import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import React, { useRef, useState } from 'react';
import bcrypt from 'bcryptjs';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


export const ForgotPassword = () => {
    const baseURL = process.env.REACT_APP_BACKEND_API_BASE_URL;
    const navigate = useNavigate();
    const [input, setInput] = useState({
        username: ''
      });
    
      const [error, setError] = useState({
        username: ''
      });
    
      const onInputChange = (e) => {
        
        const { name, value } = e.target;
        setInput((prev) => ({
          ...prev,
          [name]: value,
        }));
        validateInput(e);
      };
    
      const validateInput = (e) => {
        let { name, value } = e.target;
        setError((prev) => {
          const stateObj = { ...prev, [name]: '' };
          switch (name) {    
            case 'username':
          if (!value) {
            stateObj[name] = 'Please enter Username.';
          }
          break;
          
          }
    
          return stateObj;
        });
      };

      const handleSubmit = async (event) => {
        event.preventDefault();
        const emailAddress= input.username;
        const ForgotPassword_URL = `${baseURL}/user/forgotpassword/${emailAddress}`;

        axios.post(ForgotPassword_URL)
                .then((response) => {
                    if(response.status === 200){
                        console.log(response)
                        console.log(response.message)
                        toast.success(response.data);
                        
                        navigate("/login");
                    }
                
                    
                })
                .catch((err) => {
                    toast.error(err.response.data)
                });
        
      };
    

  return (
    <Form sm={12} md={6} className='login-signup-container' onSubmit={handleSubmit}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label >UserId</Form.Label>
        <Form.Control  className="login-signup-fields" type="text" 
        required placeholder="Enter email/Mobile Number"
        name="username"
          value={input.username}
          onChange={onInputChange}
          onBlur={validateInput} />
        {error.username && <span className="err">{error.username}</span>}
      </Form.Group>

    <Button className="authButton" variant="secondary" type="submit">
      Send Reset Password Link
    </Button>
   
  </Form>
  )
}
