import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthenticationContext = createContext();

export const AuthenticationProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState({ token: null, userName: null, role: null, userId: null });
    const [token, setToken] = useState(null);

    useEffect(() => {
        const token = sessionStorage.getItem('token');
        const username = sessionStorage.getItem('userName');
        const userId = sessionStorage.getItem('userId');
        const role = sessionStorage.getItem('role');
        if (token && username && userId && role) {
            setUser({ token, username, userId, role });
            setIsAuthenticated(true);
        }
    }, [token]);

    const login = (token, userName, userId, role) => {
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('userName', userName);
        sessionStorage.setItem('userId', userId);
        sessionStorage.setItem('role', role);
        setUser({ token, userName, userId, role });
        setToken({ token, userName, userId, role });
        setIsAuthenticated(true);
    };

    const logout = () => {
        setIsAuthenticated(false);
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userName');
        setUser({ token: null, userName: null, userId: null, role: null });
    };

    return (
        <AuthenticationContext.Provider value={{ isAuthenticated,  user, login, logout }}>
            {children}
        </AuthenticationContext.Provider>
    );
};

export const useAuthentication = () => useContext(AuthenticationContext);
