import { useState } from "react";
import { useEffect } from "react";
import { DashboardCard } from "./DashboardCard";
import { DashboardEmpty } from "./DashboardEmpty"
import { toast } from 'react-toastify';
import axios from 'axios';
import './Dashboard.css';
import { useAuthentication } from "../../Context/AuthenticationContext";

export const Dashboard = () => {
  const [orders, setOrders] = useState([]);
  const {  user, logout } = useAuthentication();
  const token = sessionStorage.getItem("token");
  const userId = sessionStorage.getItem("userId");
  const baseURL = process.env.REACT_APP_BACKEND_API_BASE_URL;

  useEffect(() => {
    async function fetchOrders(){
      axios.get(`${baseURL}/orders/retrieve/${userId}`,
        {
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`  // Add the token in the Authorization header
          }
        }
      )
                .then((response) => {

                  toast.success(response.data.message);
                  const data =  response.data;
                  setOrders(data);
                })
                .catch((err) => {
                  
                  if(err.status === 401) {
                    logout();
                  } 
                  
                  toast.error(err)
                });
      
    }
    fetchOrders();
  }, [userId]);

  return (
    <main>
      <section className="dashboard">
        <h3 >My Dashboard</h3>
      </section>

      <section className="dashboard_card">
        { orders.map((order) => (
          <DashboardCard key={order.id} order={order} />
        )) }
      </section>

      <section>
        { !orders.length && <DashboardEmpty /> }
      </section>

    </main>
  )
}