import axios from 'axios';

import bcrypt from 'bcryptjs';
import { useContext, useRef } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import validator from 'validator';
import { useAuthentication } from '../../Context/AuthenticationContext';
import { PoojaContext } from '../../Context/PoojaContext';


export const Login = ({ setSignUpShow }) => {
  const { login, user } = useAuthentication();
  const { updateLoginUserDetails } = useContext(PoojaContext);
  const userName = useRef();
  const password = useRef();
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_BACKEND_API_BASE_URL;
  const URL = `${baseURL}/user/login`;
  const role = user.role;
  let loginUserId = "";


  const handleSubmit = async (event) => {
    event.preventDefault();

    const userId = userName.current.value;
    const enteredPassword = password.current.value;
    if (validator.isEmail(userId)) {
      console.log('Valid Email :)')
      loginUserId = "emailAddress";
    }
    if (validator.isMobilePhone(userId)) {

      loginUserId = "phoneNumber";
    }
    const authDetail = {
      [loginUserId]: userId
    }

    try {

      const response = await axios.post(URL, authDetail);

      if (response) {

        if (response.status === 200) {

          const logindetails = response.data;

          if (logindetails != null) {
            const passwordCompare = bcrypt.compareSync(enteredPassword, logindetails.password);
            if (passwordCompare) {

              updateLoginUserDetails(logindetails);
              login(logindetails.token, logindetails.username, logindetails.userId, logindetails.role);

              if (logindetails.role === "ADMIN") {

                navigate("/addproducts");
              } else {
                navigate("/dashboard");
              }

            } else {
              toast.error("Login failed - Invalid Credentials")
            }

          } else {
            toast.error('Login Failed - Try again with valid credentials')
          }
        }
      }

    } catch (error) {

      if (!error?.response) {
        toast.error('No Server Response');
      } else {
        if (error.response.data.message != null) {
          toast.error(error.response.data.message)
        } else {
          toast.error('Login Failed - Try again with valid credentials')
        }

      }

    }
  };

  return (
    <Form sm={12} md={6} className='login-signup-container' onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label >UserId</Form.Label>
        <Form.Control ref={userName} className="login-signup-fields" type="text" required placeholder="Enter email/10 Digit Mobile Number" />
        <Form.Text className="text-muted">
          We'll never share your email/phone number with anyone else.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control ref={password} className="login-signup-fields" type="password" required placeholder="Password" />
      </Form.Group>
      <Form.Group>
        <Form.Label className="login-signup-label"> <Link to="/forgotpassword">Forgot Password? </Link></Form.Label>
      </Form.Group>


      <Button className="authButton" variant="secondary" type="submit">
        Submit
      </Button>
      <Form.Label className="login-signup-label">New to UthsavaSoukhya? <Link onClick={() => { setSignUpShow(true) }} to="/login">Signup Now </Link></Form.Label>

    </Form>

  )
}
