import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import React, { useRef, useState } from 'react';
import bcrypt from 'bcryptjs';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';


export const ResetPassword = () => {
    const baseURL = process.env.REACT_APP_BACKEND_API_BASE_URL;
    const token = useParams().token;
    const navigate = useNavigate();
    const [input, setInput] = useState({
        newpassword: '',
        confirmPassword: '',
      });
    
      const [error, setError] = useState({
        newpassword: '',
        confirmPassword: '',
      });
    
      const onInputChange = (e) => {
        
        const { name, value } = e.target;
        setInput((prev) => ({
          ...prev,
          [name]: value,
        }));
        validateInput(e);
      };
    
      const validateInput = (e) => {
        let { name, value } = e.target;
        setError((prev) => {
          const stateObj = { ...prev, [name]: '' };
          switch (name) {    
            case 'newpassword':
              if (!value) {
                stateObj[name] = 'Please enter Password.';
              } else if (input.confirmPassword && value !== input.confirmPassword) {
                stateObj['confirmPassword'] =
                  'Password and Confirm Password does not match.';
              } else {
                stateObj['confirmPassword'] = input.confirmPassword
                  ? ''
                  : error.confirmPassword;
              }
              break;
    
            case 'confirmPassword':
              if (!value) {
                stateObj[name] = 'Please enter Confirm Password.';
              } else if (input.newpassword && value !== input.newpassword) {
                stateObj[name] = 'Password and Confirm Password does not match.';
              }
              break;
    
            default:
              break;
          }
    
          return stateObj;
        });
      };
      console.log(token)

      const handleSubmit = async (event) => {
        event.preventDefault();
        if (input.newpassword !== input.confirmPassword) {
          toast.error('Password and Confirm Password do not match.');
          return;
        }
        
        const newpassword = input.newpassword;
          
          const URL = `${baseURL}/user/resetpassword`;
          const salt =  bcrypt.genSaltSync(10);
          const securePass =  bcrypt.hashSync(newpassword, salt);
          
          const resetDetail = {
            hashPassword: securePass,
            token: token
          }

          console.log(URL)
          axios.post(URL,resetDetail)
          .then((response) => {
            console.log(response)
            if(response.status === 200){
               
                toast.info(response.data)
                navigate("/login")
            }
              
            }).catch((err) => {
                toast.error(err.response.data)
                navigate("/forgotpassword")
            });
            
      };
    

  return (
    <Form sm={12} md={6} className='login-signup-container' onSubmit={handleSubmit}>
    <Form.Group className="mb-3" >
      <Form.Label >New Password</Form.Label>
      <Form.Control className="login-signup-fields" 
      name="newpassword"
      type="password"  
      required 
      placeholder="Enter new password"
      value={input.newpassword}
      onChange={onInputChange}
      onBlur={validateInput}
       />
       {error.newpassword && <span className="err">{error.newpassword}</span>}
      
    </Form.Group>

    <Form.Group className="mb-3" >
      <Form.Label>Confirm Password</Form.Label>
      <Form.Control className="login-signup-fields" 
      name="confirmPassword"
      type="password" 
      required 
      placeholder="Confirm Password" 
      value={input.confirmPassword}
      onChange={onInputChange}
      onBlur={validateInput}
      />
      {error.confirmPassword && (
          <span className="err">{error.confirmPassword}</span>
        )}
    </Form.Group>

    <Form.Group className="mb-3" id="formGridCheckbox">
                <Form.Check type="checkbox" required label="By continuing, I agree to terms of use and privacy policy" />
    </Form.Group>

    <Button className="authButton" variant="secondary" type="submit">
      Reset Password
    </Button>
   
  </Form>
  )
}
