import React, { useState } from 'react'
import {Button, Col, Form, Row } from 'react-bootstrap';
import bcrypt from 'bcryptjs';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const SignUp = ({setSignUpShow}) => {
    const baseURL = process.env.REACT_APP_BACKEND_API_BASE_URL;
    const [validated, setValidated] = useState(false);
    const [passwordError, setPasswordError] = useState(null);
    const initialFormData = {
        name: "",
        email:"",
        password: "",
        confirmPassword: "",
        address1:"",
        address2:"",
        city:"",
        state:"",
        phoneNumber: ""
    }
    const [form_Data, set_Form_Data] = useState(initialFormData);
    const navigate = useNavigate();

    const handleSubmit = (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.stopPropagation();
        setValidated(true);
        return;
      } if (form_Data.password !== form_Data.confirmPassword) {
        setPasswordError("Passwords do not match");
        toast.error('Password and Confirm Password do not match.');
        return;
      } else {
        setPasswordError(null);
      }
      handleSignUp(event);
    };

    const chngFn = (event) => {
        const { name, value } = event.target;
        set_Form_Data({
            ...form_Data,
            [name]: value,
        });
    };

    async function handleSignUp(event) {
        event.preventDefault();
        const form = event.currentTarget;
        const emailAddress= event.currentTarget.email.value;
        const salt =  bcrypt.genSaltSync(10);
        const securePass =  bcrypt.hashSync(event.currentTarget.password.value, salt);
        const signUpDetails = {
            username: event.currentTarget.name.value,
            emailAddress: emailAddress,
            hashPassword: securePass,
            address: (event.currentTarget.address1 ? event.currentTarget.address1.value : "")
            .concat(",").concat((event.currentTarget.address2 ? event.currentTarget.address2.value : ""))
            .concat(",").concat((event.currentTarget.city ? event.currentTarget.city.value : ""))
            .concat(",").concat((event.currentTarget.state ? event.currentTarget.state.value : ""))
            .concat(",").concat((event.currentTarget.postcode ? event.currentTarget.postcode.value : "")),
            phoneNumber:event.currentTarget.phoneNumber.value
        }
       /** GET and Post API call */
       const UserNameAvaialability_URL = `${baseURL}/user/${emailAddress}`;
       axios.get(UserNameAvaialability_URL)
       .then((response) => {

                axios.post(`${baseURL}/user/register`, signUpDetails)
                .then((response) => {
                    if(response.status === 200){
                        toast.success(response.data.message);
                        setSignUpShow(false);
                        navigate("/login");
                    }
                
                    
                })
                .catch((err) => toast.error(err));
    })
    .catch((error) => {
        setValidated(false);

        toast.error(error.response.data)
        
    });
      
        
    }

    return (
        <Form className="login-signup-container" noValidate validated={validated} onSubmit={handleSubmit}>
             <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Form.Label>Name</Form.Label>
                    <Form.Control className="login-signup-fields"  
                    required 
                    type="text" 
                    name="name" 
                    placeholder="Name"
                    value={form_Data.name}
                    onChange={chngFn}
                    pattern="^[a-zA-Z0-9]+$"
                    isInvalid={
                        validated &&
                        !/^[a-zA-Z0-9]+$/.test(form_Data.name)
                    } />
                    <Form.Control.Feedback type="invalid">Please enter a valid username (alphanumeric characters only).</Form.Control.Feedback>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control className="login-signup-fields" type="email" placeholder="Enter email" 
                    name= "email"
                    value={form_Data.email}
                    onChange={chngFn}
                    required
                    isInvalid={
                        validated &&
                        !/^[a-zA-Z0-9_.±]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/.test(form_Data.email)
                    }/>
                    <Form.Control.Feedback type="invalid">
                                Please enter a valid email address.
                            </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control className="login-signup-fields" type="password" placeholder="Password"
                    required
                    name="password"
                    value={form_Data.password}
                    onChange={chngFn}
                    minLength={6}
                    pattern={form_Data.password}
                    
                    isInvalid={validated && form_Data.password.length < 6}
                     />
                      <Form.Control.Feedback type="invalid">
                                Password must be at least 6 characters long.
                            </Form.Control.Feedback>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridConfirmPassword">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            className="login-signup-fields"
            type="password"
            placeholder="Confirm Password"
            required
            name="confirmPassword"
            value={form_Data.confirmPassword}
            onChange={chngFn}
            isInvalid={
              validated &&
              (form_Data.password !== form_Data.confirmPassword ||
                form_Data.confirmPassword.length < 6)
            }
          />
          <Form.Control.Feedback type="invalid">
            {form_Data.password !== form_Data.confirmPassword
              ? "Passwords do not match."
              : "Password must be at least 6 characters long."}
          </Form.Control.Feedback>
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>

            </Row>

            <Row className="mb-3">
            <Form.Group controlId="phoneNumber">
                            <Form.Label>Mobile Number</Form.Label>
                            <Form.Control className="login-signup-fields"  
                                name="phoneNumber"
                                placeholder='Mobile Number'
                                value={form_Data.phoneNumber}
                                onChange={chngFn}
                                pattern="^\d{10}$"
                                required
                                isInvalid={
                                    validated &&
                                    !/^\d{10}$/.test(form_Data.phoneNumber)
                                }
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter a valid 10-digit phone number.
                            </Form.Control.Feedback>
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>

            </Row>

            <Form.Group className="mb-3" controlId="formGridAddress1">
                <Form.Label>Address (Optional)</Form.Label>
                <Form.Control type="address1" className="login-signup-fields" placeholder="1234 Main St" name="address1" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGridAddress2">
                <Form.Label>Address 2</Form.Label>
                <Form.Control type="address2" className="login-signup-fields" placeholder="Apartment, studio, or floor - Optional" name="address2" />
            </Form.Group>

            <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridCity">
                    <Form.Label>City</Form.Label>
                    <Form.Control type="city" className="login-signup-fields" placeholder="City" name = "city" />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridState">
                    <Form.Label>State</Form.Label>
                    <Form.Select type="state"  className="login-signup-fields" defaultValue="Karnataka" name="state" >
                        <option>Karnataka</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridZip">
                    <Form.Label>Zip</Form.Label>
                    <Form.Control type="postcode" className="login-signup-fields"  placeholder="Postal Code" name="postcode" />
                </Form.Group>
            </Row>

            <Form.Group className="mb-3" id="formGridCheckbox">
                <Form.Check type="checkbox" required label="By continuing, I agree to terms of use and privacy policy" />
            </Form.Group>

            <Button className="authButton" variant="secondary" type="submit" >
                Submit
            </Button>
        </Form>
    );
}
